import React from "react";
import Helmet from "react-helmet";
import qs from "query-string";
import { graphql, Link } from "gatsby";
import sortBy from "lodash/sortBy";
import Layout from "../layout/MainLayout";
import config from "../../data/SiteConfig";
import BookTimeline from "../components/BookTimeline/BookTimeline";
import BookSummaryList from "../components/BookSummaryList/BookSummaryList";
import "./index.css";

class Index extends React.Component {
  render() {
    const { search } = this.props.location;
    const query = qs.parse(search);
    const { edges } = this.props.data.allBooks;
    const books = sortAndFilter(edges, query);
    return (
      <Layout>
        <div className="timeline-container">
          <BookTimeline books={books} />
        </div>
        <div className="app-wrapper">
          <Helmet title={config.siteTitle} />
          <div className="intro">
            <div className="intro-content">
              <h3>Books I've read</h3>
              <p>
                Here you find my highlighted passages and notes for{" "}
                <strong>{books.length}</strong> of the books I've read. I update
                this site semi-regularly, to add more books. Sometimes, the
                notes and highlights for some books might also change as I
                re-read some of them.
              </p>
              <p>
                The inspiration for this project is{" "}
                <a href="https://sivers.org/bfaq">
                  Derek Sivers and his booknotes.
                </a>
                . I do this to{" "}
                <a href="https://fs.blog/2014/05/remembering-what-you-read/">
                  better remember what I read.
                </a>{" "}
                This is an attempt to make{" "}
                <a href="https://fs.blog/2013/11/taking-notes-while-reading/">
                  better notes
                </a>
                , and to help future me see how I got to where I got to.
                Long-term, I would like to use this to see patterns of growth in
                my thinking and beliefs.
              </p>
            </div>
          </div>
          <ul className="mainNav">
            <li className="navItem">
              <Link getProps={isPartiallyActive} to={"/?sort=rating"}>
                Highest Rated
              </Link>
            </li>

            <li className="navItem">
              <Link getProps={isPartiallyActive} to={"/?sort=dateRead"}>
                Most Recent
              </Link>
            </li>

            <li className="navItem">
              <Link to={"/?sort=title&order=ASC"} getProps={isPartiallyActive}>
                Title A-Z
              </Link>
            </li>
            <li className="navItem">
              <Link to={"/?sort=title"} getProps={isPartiallyActive}>
                Title Z-A
              </Link>
            </li>
          </ul>

          <BookSummaryList books={books} />
        </div>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  {
    allBooks(sort: { fields: dateRead, order: DESC }) {
      edges {
        node {
          title
          dateRead
          rating
          isbn13
          bookCoverURL
          authors
          asin
          isbn10
          description
          amazonLink
          bookCoverImage {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
      }
    }
  }
`;

function sortAndFilter(data, query) {
  const validSortKeys = ["rating", "dateRead", "title", "isbn10", "isbn13"];
  const { sort = "dateRead", order = "DESC" } = query;

  const denoded = data.map(item => item.node);
  let sorted = denoded;
  if (validSortKeys.indexOf(sort) > -1) {
    sorted = sortBy(denoded, sort);
  }

  if (order === "DESC") {
    sorted.reverse();
  }
  return sorted;
}

const isPartiallyActive = props => {
  const {
    isPartiallyCurrent,
    href,
    location: { search }
  } = props;
  let forceCurrent = false;
  if (href.toString() === `/${search.toString()}`) {
    forceCurrent = true;
  }
  const returnObj =
    isPartiallyCurrent || forceCurrent
      ? { className: "navItem-button navItem-button--active" }
      : { className: "navItem-button" };

  return returnObj;
};
