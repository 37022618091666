import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { createSlug } from "../../../helpers";
import "./BookTimeline.css";

const BookTimeline = () => (
  <StaticQuery query={bookTimelineQuery} render={view} />
);

export default BookTimeline;

const bookTimelineQuery = graphql`
  {
    allBooks(sort: { fields: dateRead, order: DESC }) {
      edges {
        node {
          title
          dateRead
        }
      }
    }
  }
`;

const view = data => {
  const { allBooks } = data;
  const sortByYear = {};

  allBooks.edges.forEach(book => {
    const { title, dateRead } = book.node;
    const year = dateRead.split("-")[0]; // dateRead is in the YYYY-MM-DD format
    if (sortByYear[year]) {
      sortByYear[year].push(title);
    } else {
      sortByYear[year] = [title];
    }
  });

  const years = Object.keys(sortByYear).reverse();
  let left = false;

  const yearCollections = years.map((year, ind) => {
    left = !left;
    const alignment = left ? `left` : `right`;
    return titlesThisYear(year, sortByYear[year], alignment);
  });

  return (
    <div className="bookTimeline-container">
      {yearCollections.map(item => item)}
    </div>
  );
};

function titlesThisYear(year, titles, alignment) {
  const titlesList = titles.map((title, index) => {
    const url = createSlug(title);
    return (
      <li key={`title_${index}_${year}`} className="bookTimeline-listItem">
        <Link className="bookTimeline-listItemLink" to={`/${url}`}>
          {title}
        </Link>
      </li>
    );
  });
  return (
    <ul key={`list-${year}`} className={`bookTimeline-yearList ${alignment}`}>
      <div key={`label-${year}`} className="bookTimeline-yearLabel">
        {year}
      </div>
      {titlesList}
    </ul>
  );
}
